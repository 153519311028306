.signuporsignin-body {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 10px;
}

.signuporsignin-content {
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media(max-width: 600px) {
        max-width: 600px
    }
}

.signuporsignin-logo {
    background: rgb(10,105,218);
    background: radial-gradient(circle, rgba(10,105,218,0.21752450980392157) 0%, rgba(255,255,255,1) 59%);
    width: 50%;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32pt;
    font-family: "Unbounded";
}

.signinorsignup-selector {
    display: flex;
    width: 100%;
}

.activeButton {
    background-color: red;
    border: 2px solid red;
    margin-left: 10px;
}

.mixed-input {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.mixed-left {
    padding-left: 10px;
}

.mixed-right {
    padding-right: 10px;
}

.first-input {
    margin-top: 20px;
}

input {
    background-color: #f6f8fa;
    border: 1px solid #d0d7de;
    padding: 7px 12px;
    border-radius: 15px;
    width: 100%;
    font-size: 12pt;
}

.mixed-field-left {
    width: calc(100% - 10px);
    margin-left: 10px;
}

.mixed-field-right {
    width: calc(100% - 10px);
    margin-right: 10px;
}

.regular-field {
    margin-top: 10px;
}

.turnstile {
    padding-top: 20px;
}

#sign-up-error-field {
    color: red;
    font-size: 10pt;
    text-align: center;
}