.models-body {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
}

.models-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    overflow-x: scroll;
    padding: 0px 10px;
}

.model-button-image {
    width: 80px;
    height: 80px;
}

.model-button-text {
    padding-top: 10px;
    font-size: 10pt;
}

.current-model-title-body {
    padding-left: calc((100% - 1200px) / 2);
    padding-top: 10px;
    font-size: 16pt;
}

.current-model-title-container { 
    padding-left: 10px;
}