#reviews-body {
    display: flex;
    width: 100%;
    margin-top: 10px;
    flex-direction: column;
    
}

#reviews-content {
    display: flex;
    padding-left: calc((100vw - 1180px)/2);
    padding-right: 10px;
    overflow-x: scroll;
    @media(max-width: 1200px) {
        padding-left: 10px;
    }
}

#reviews-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: calc((100vw - 1180px) / 2);
    @media(max-width: 1200px) {
        padding-right: 10px;
    }
}

#all-reviews-button {
    margin-top: -10px;
}

.review {
    display: flex;
    height: 300px;
    aspect-ratio: 2/2.5;
    background-color: #f6f8fa;
    border: 1px solid #d0d7de;
    flex-direction: column;
    border-radius: 15px;
    padding: 10px;
    margin-right: 10px;
}

.review-item {
    padding-top: 10px;
    padding-bottom: 10px;
    opacity: 0.4;
    font-size: 11pt;
}

.review-text {
    font-size: 11pt;
}

