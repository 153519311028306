#sections-body {
    display: flex;
    width: 100%;
    justify-content: center;
}

#sections-content {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 0px 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.section-body {
    width: calc(20% - 7px);
    aspect-ratio: 1/1;
    background-color: #f6f8fa;
    border: 1px solid #d0d7de;
    cursor: pointer;
    display: grid;
    border-radius: 15px;
    margin-top: 10px;
    overflow: hidden;
    text-decoration: none;
    @media(max-width: 600px) {
        width: calc(50% - 5px);
    }
}

@media(min-width: 600px) {
    .section-body:hover {
        border: 1px solid black;
    }

    .section-body:hover .section-image {
        margin-top: 20%;
    }

    .section-body:hover .section-title {
        color: black;
        font-size: 30pt;
        margin-top: -50%;
    }
}

@media(max-width: 600px) {
    .section-body {
        transition: 0.1s;
    }

    .section-body:active {
        scale: 0.95;
    }
}

.section-title {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    font-size: 50pt;
    overflow: hidden;
    color: #d0d7de;
    transition: 0.4s;
    margin-top: -50%;
    @media(max-width: 600px) {
        font-size: 24pt;
        margin-top: -60%;
    }
}

.section-image {
    display: flex;
    width: 100%;
    aspect-ratio: 1/1;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    transition: 0.4s;
    @media(max-width: 600px) {
        margin-top: 10%;
    }
}

.section-image > img {
    width: 100%;
}

.section-body > * {
    grid-area: 1/1/1/1;
}