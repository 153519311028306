.item {
    display: flex;
    align-items: flex-start;
    width: calc(25% - 10px);
    aspect-ratio: 1/1.35;
    border-radius: 15px;
    border: 1px solid #d0d7de;    
    margin-right: 10px;
    margin-top: 10px;
    padding: 10px;
    flex-direction: column;
    cursor: pointer;
    transition: 0.1s;
    @media(max-width: 600px) {
        width: calc(50% - 10px);
    }
}

@media(min-width: 600px) {
    .item:hover {
        border: 1px solid;
    }

    .item:hover > img {
        padding: 5px;
    }
}

.item-name { 
    height: 3em;
    @media(max-width: 600px) {
        height: 4em;
    }
}

.item > img {
    transition: 0.1s;
    width: 100%;
    padding: 10px;
}

.item-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    flex: auto;
    padding-top: 10px;

}

.item-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.avaibility { 
    font-size: 10pt;
}

.item-available {
    color: #67cc45;
}

.item-delivery {
    color: #F9A825;
}

.item-unavailable {
    color: #F44336;
}

.bonus-sign-container {
    display: flex;
    width: 100%;
}

.bonus-sign {
    display: flex;
    padding: 5px;
    border-radius: 15px;
    background-color: #0A69DA;
    align-items: center;
}

.bonus-sign > img {
    height: 20px;
}

.bonus-sign-text {
    padding-left: 5px;
    color: white;
    padding-right: 5px
}