.item-view-section-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #f6f8fa;
    border: 1px solid #d0d7de;
    margin-top: 10px;
    border-radius: 15px;
}

.item-view-price-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14pt;
}

.item-view-price-bonus {
    font-size: 10pt;
}

.item-view-card-price {
    opacity: 0.5;
    font-size: 10pt;
}

.item-section-title {
    font-weight: bold;
}

.item-section-text {
    padding-top: 10px;
}

.trade-in-container {
    padding: 10px 0px;
}

.trade-in-contents {
    padding: 0px 10px;
}

.item-section-tradein-image {
    padding: 10px 0px;
}

.charline {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.charline-title {
    opacity: 0.5;
}