#reviews-view-body {
    display: flex;
    width: 100%;
    justify-content: center;
}

#reviews-view-content {
    display: flex;
    width: 100%;
    max-width: 700px;
    padding: 10px;
    flex-direction: column;
    @media(display: flex) {
        width: 100%;
    }
}

.reviewforview-body {
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    background-color: #f6f8fa;
    border: 1px solid #d0d7de;
    margin-top: 10px;
}

.reviewforview-item { 
    opacity: 0.5;
    padding-top: 10px;
    padding-bottom: 10px;
}