.item-view-body {
    display: flex;
    width: 100%;
    justify-content: center;
}

.item-view-content {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    padding-top: 20px;
    justify-content: space-between;
    @media(max-width: 850px) {
        flex-direction: column;
    }
}

.item-view-side {
    display: flex;
    width: 50%;
    @media(max-width: 850px) {
        width: 100%;
    }
}

.item-view-first-side {
    width: 55%;
    position: sticky;
    @media(max-width: 850px) {
        width: 100%;
    }
    @media(min-width: 850px) {
        top: 80px;
        position: sticky;
    }
}

.item-view-second-side {
    width: 35%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    @media(max-width: 850px) {
        width: 100%;
    }
}

.images-swiper {
    overflow: hidden;
    aspect-ratio: 1/1;
    width: 100%;
    @media(min-width: 850px) {
        top: 80px;
        position: sticky;
    }
}

.swiper-image-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.swiper-image-wrapper > img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    padding: 5px;
}

.swiper-button-prev::after {
    display: none;
}

.swiper-button-next::after {
    display: none;
}

@media(max-width: 850px) {
    .swiper-btn {
        display: none;
    }
}

.item-view-item-title {
    font-size: 15pt;
}

.item-view-avaibility {
    margin-top: 5px;
}

.outer-swiper {
    width: 100%;
    aspect-ratio: 1/1;
}

.alert-section-shadow {
    box-shadow: 0px 0px 500px black;
}