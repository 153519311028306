* {
    font-family: "Inter";
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

span {
    width: 10px;
    height: 10px;
}

div::-webkit-scrollbar {
    display: none;
}

iframe {
    display: none;
}

.VD_BUTTON {
    border-radius: 20px;
}