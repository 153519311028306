#perks-body {
    width: 100%;
    padding: 10px 0px;
    margin-top: 10px;
    background-color: #f6f8fa;
    border-top: 1px solid #d0d7de;
    border-bottom: 1px solid #d0d7de;
}

#perks-content {
    display: flex;
    overflow-y: scroll;
    width: 100%; 
    height: 100%;
    padding-left: calc((100vw - 1180px)/2);
    padding-right: 10px;
    @media(max-width: 1200px) {
        padding-left: 10px;
    }
}

.perk {
    display: grid;
    height: 200px;
    aspect-ratio: 3/2;
    display: flex;
    background-color: white;
    border-radius: 15px;
    margin-right: 10px;
    padding: 20px;
    flex-direction: column;
    border: 1px solid #d0d7de;
    @media(max-width: 600px) {
        height: 150px;
    }
}

.perk-icon {
    width: 70px;
    height: 70px;
    @media(max-width: 600px) {
        width: 40px;
        height: 40px;
    }
}

.perk-text { 
    font-weight: 500;
    font-size: 16pt;
    @media(max-width: 600px) {
        font-size: 12pt;
    }
}