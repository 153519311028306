#telegram-body {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 10px;
}

#telegram-content {
    display: flex;
    width: 100%;
    max-width: 1180px;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #d0d7de;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 600px) {
        justify-content: center;
        flex-direction: column;
        padding: 30px 20px;
    }
}

#tg-icon {
    width: 60px;
    @media(max-width: 600px) {
        width: 120px;
    }
}

#tg-info {
    display: flex;
    align-items: center;
    @media(max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
} 

#tg-title {
    font-size: 16pt;
    font-weight: 500;
    @media(max-width: 600px) {
        padding-top: 10px;
    }
    @media(min-width: 600px) {
        padding-left: 20px;
    }
}

#tg-desc {
    @media(max-width: 600px) {
        padding-bottom: 20px;
    }
    @media(min-width: 600px) {
        padding-left: 20px;
    }
}