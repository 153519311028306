#infobar-body {
    display: flex;
    width: 100%;
    padding-top: 10px;
    overflow-x: scroll;
}

#infobar-content { 
    display: flex;
    overflow-x: auto;
    padding-left: 10px;
    @media(min-width: 1200px) {
        padding-left: calc((100vw - 1180px) / 2);
        
    };
}

.infobar-margin {
    margin-left: 10px;
}

.infobar-requisites {
    margin-top: 2px;
    font-size: 10pt;
    opacity: 0.5;

}