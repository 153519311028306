#header-body {
    display: flex;
    width: 100%;
    height: 60px;
    background-color: #f6f8fa;
    justify-content: center;
    border-bottom: 1px solid #d0d7de;
    top: 0;
    position: sticky;
    z-index: 999999;
}

#header-content {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
}

#header-buttons {
    display: flex;
}