#banner-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

#banner-content {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 10px 10px 0px 10px;
}

#banner-body {
    width: 100%;
    background-color: #f5f5f7;
    border: 1px solid #d0d7de;
    border-radius: 15px;
    display: flex;
    @media(max-width: 600px) {
        flex-direction: column-reverse;
    }
}

#banner-image {
    width: 50%;
    border-radius: 15px;
    @media(max-width: 600px) {
        width: 100%;
    }
}

#banner-contents {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    @media(max-width: 600px) {
        padding-top: 40px;
    }
}

#banner-title {
    font-size: 22pt;
    font-weight: 600;
    display: flex;
    align-items: center;
}

#banner-logo {
    height: 1em;
    padding-right: 5px;
    margin-top: -6px;
}

#banner-go-button {
    margin-top: 20px;
}

#banner-caption {
    display: flex;
}

.banner-logo {
    font-family: "Unbounded";
    padding-left: 5px;
}