.notification {
    display: flex;
    width: 100%;
    padding: 10px;
    border: 1px solid #d0d7de;
    border-radius: 10px;
    background: rgb(10,105,218);
    background: linear-gradient(25deg, rgba(10,105,218,1) 0%, rgba(34,91,159,1) 100%);
    color: white;
    pointer-events: all;
}

.notification-image {
    justify-content: center;
    font-size: 18pt;
    display: flex;
    padding: 5px 15px 0px 10px;
}

.notification-image > img {
    width: 40px;
    height: 40px;
}

.notification-info {
    width: 100%;

}

.notification-title {
    padding-bottom: 5px;
}

.notification-text {
    opacity: 0.5;
}

.notification-buttons {
    display: flex;
    padding-top: 10px;
    justify-content: flex-end;
}