#userpage-body {
    display: flex;
    width: 100%;
    justify-content: center;
}

#userpage-content {
    width: 100%;
    max-width: 600px;
    padding-top: 20px;
}

#userpage-pic {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 72pt;
    width: 200px;
    height: 200px;
    border-radius: 500px;
    margin-bottom: 20px;
    background-color: #f6f8fa;
    border: 1px solid #d0d7de;
}

#userpage-user {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: "Unbounded";
    font-size: 24pt;
}

#userpage-order-history {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
}

.userpage-order {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid #d0d7de;
    margin-top: 10px;
}

.delivery-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.delivery-status-circle {
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
    background-color: #0a69da;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16pt;
    color: white;
}

.delivery-status-line {
    height: 2px;
    width: 100%;
    background-color: #0a69da;
}

.userpage-order-header {
    display: flex;
    justify-content: space-between
}

.userpage-order-items-images {
    display: flex;
    min-width: calc(100% + 20px);
    overflow-x: auto;
    margin: -10px;
    margin-top: 10px;
    padding-bottom: 10px;
}

.userpage-order-items-images > img {
    width: 80px;
    height: 80px;
    padding: 10px;
    border: 1px solid #d0d7de;
    border-radius: 15px;
    margin-left: 10px;
}

.userpage-order-item-list {
    padding: 10px;
    border-radius: 15px;
    margin-top: 10px;
    border: 1px solid #d0d7de;
    background-color: #f6f8fa;
}

.userpage-order-item-list-price {
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
}

.userpage-order-item-list-title {
    margin-top: 10px;
}

.userpage-order-item-list-bonus {
    font-weight: 400;
    margin-left: 10px;
    font-size: 10pt;
}

.circle-disabled {
    background-color: #f6f8fa;
    color: black;
}

.line-disabled {
    background-color: #f6f8fa;
}

.no-orders-sign {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-orders-sign > img {
    width: 100px;
    height: 100px;
    opacity: 0.2;
}

.no-orders-title {
    opacity: 0.5;
}