.thanks-for-order {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.thanks-icon {
    width: 100px;
    height: 100px;
    margin-top: 20px;
    background-color: #0a69da;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36pt;
    border-radius: 1000px;
    margin-bottom: 10px;
}

.thanks-title {
    font-size: 16pt;

}

.thanks-text {
    margin-bottom: 20px;
    margin-top: 2px;
    opacity: 0.5;
}